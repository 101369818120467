export { LoginPage, getLoginServerSideProps } from './login/_app'
export type { LoginPageProps } from './login/_app'

export { RegisterV2Page, getRegisterServerSideProps } from './register/_app'
export type { RegisterPageV2Props } from './register/_app'

export {
  LineVerifyPage,
  getLineVerifyServerSideProps,
} from './line/verify/_app'
export type { RegisterLineProps } from './line/verify/_app'

export { LineLinkPage, getLineLinkServerSideProps } from './line/link/_app'
export type { LineLinkProps } from './line/link/_app'

export { ResetPinPage, getResetPinServerSideProps } from './reset-pin/_app'
export type { ResetPinProps } from './reset-pin/_app'

export { DailySpinPage } from './daily-spin/_app'

export { WalletPage } from './wallet/_app'
export { VipPage } from './vip/_app'
export { ProfilePage } from './profile'
export { ProfileDetailsPage } from './profile-details/_app'

export { SetBankPage } from './profile/set-bank'
export type { SetBankPageProps } from './profile/set-bank'

export { SetPhonePage } from './profile/set-phone'

export { SetPhoneBankPage } from './profile/set-phone-bank'

export { SetKycPage } from './profile/set-kyc'

export { SetProfilePage } from './profile/set-profile'

export { AffiliatePage } from './affiliate/_app'
export { NotificationsPage } from './notifications/_app'
export { MaintenancePage } from './maintenance/_app'
export type { MaintenanceProps } from './maintenance/_app'

export { AchievementPage } from './achievement/_app'

export { FaqPage } from './faq/_app'

export { GuildPage } from './guild/_app'
export { ChampionPage } from './champion/_app'
export { PigShopPage } from './pig-shop/_app'
export { DailyQuestsPage } from './daily-quests/_app'
export { CreateGuildPage } from './guild-create/_app'
export type { ICreateGuildProps } from './guild-create/_app'
